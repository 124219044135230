import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Splash: FC = () => (
  <Box
    sx={{
      display: 'grid',
      placeItems: 'center center',
      width: '100vw',
      height: '100vh',
    }}>
    <CircularProgress size={100} />
  </Box>
);

export default Splash;
